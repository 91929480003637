const initialState = {
    vacationUsageList: []
}

const VacationUsage = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_VACATION_USAGE':
            return {
                ...state,
                vacationUsageList: action.vacationUsageList
            }
        default:
            return state
    }
}

export default VacationUsage