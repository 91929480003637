const initialState = {
    getTotalDebt: 0,
    getLastAdded10User: [],
    getLastDebtAdded10User: [],
    getLastVacationAdded10User: [],
    getTotalDebtFileCount: 0,
    getTotalUserCount: 0
}

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TOTAL_DEBT':
            return {
                ...state,
                getTotalDebt: action.getTotalDebt
            }
        case 'GET_LAST_ADDED_10_USER':
            return {
                ...state,
                getLastAdded10User: action.getLastAdded10User
            }
        case 'GET_LAST_DEBT_ADDED_10_USER':
            return {
                ...state,
                getLastDebtAdded10User: action.getLastDebtAdded10User
            }

        case 'GET_LAST_VACATION_ADDED_10_USER':
            return {
                ...state,
                getLastVacationAdded10User: action.getLastVacationAdded10User
            }

        case 'GET_TOTAL_DEBT_FILE_COUNT':
            return {
                ...state,
                getTotalDebtFileCount: action.getTotalDebtFileCount
            }

        case 'GET_TOTAL_USER_COUNT':
            return {
                ...state,
                getTotalUserCount: action.getTotalUserCount
            }

        default:
            return state
    }
}

export default CommonReducer