const initialState = {
    claimCategoryListWithClaims: []
}


const claimCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CLAIM_CATEGORY_LIST":
            return {
                ...state,
                claimCategoryListWithClaims: action.claimCategoryListWithClaims
            }
        default:
            return state;
    }
}

export default claimCategoryReducer;