const initialState = {
    userAddressInfoChange: {},
    userAddressInfoNotSeenChangeList: [],
    userAddressInfoSeenChangeList: []
}

const userAddressInfoChangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_ADDRESS_INFO_CHANGE':
            return {
                ...state,
                userAddressInfoChange: action.userAddressInfoChange
            }
        case 'GET_NOT_SEEN_ADDRESS_INFO_CHANGE':
            return {
                ...state,
                userAddressInfoNotSeenChangeList: action.userAddressInfoNotSeenChangeList
            }
        case 'GET_SEEN_ADDRESS_INFO_CHANGE':
            return {
                ...state,
                userAddressInfoSeenChangeList: action.userAddressInfoSeenChangeList
            }
        default:
            return state;
    }
}

export default userAddressInfoChangeReducer;