const initialState = {
    getSuggestionById: {},
    getRepliedSuggestionList: [],
    getNotRepliedSuggestionList: []
}

const SuggestionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SUGGESTION_LIST':
            return {
                ...state,
                getSuggestionList: action.getSuggestionList,
                getSuggestionListRead: action.getSuggestionListRead,
                getSuggestionListNotRead: action.getSuggestionListNotRead
            }
        case 'GET_SUGGESTION_BY_ID':
            return {
                ...state,
                getSuggestionById: action.getSuggestionById
            }
        case 'GET_REPLIED_SUGGESTİON_LIST':
            return {
                ...state,
                getRepliedSuggestionList: action.getRepliedSuggestionList
            }
        case 'GET_NOT_REPLIED_SUGGESTİON_LIST':
            return {
                ...state,
                getNotRepliedSuggestionList: action.getNotRepliedSuggestionList
            }
        default:
            return state;
    }
}

export default SuggestionReducer