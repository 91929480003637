const initialState = {
    lmsUnitData: [],
    lmsUnitTitleData:[]
}


const UnitTitleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LMS_GET_UNIT':
            return {
                ...state,
                lmsUnitData: action.lmsUnitData
            }
        case 'LMS_GET_UNIT_TITLE':
            return {
                ...state,
                lmsUnitTitleData: action.lmsUnitTitleData
            }

        default:
            return state
    }
}

export default UnitTitleReducer