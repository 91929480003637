const initialState = {
    userMailInfoChange: {},
    userMailInfoNotSeenChangeList: [],
    userMailInfoSeenChangeList: []
}

const userMailInfoChangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_MAIL_INFO_CHANGE':
            return {
                ...state,
                userMailInfoChange: action.userMailInfoChange
            }
        case 'GET_NOT_SEEN_MAIL_INFO_CHANGE':
            return {
                ...state,
                userMailInfoNotSeenChangeList: action.userMailInfoNotSeenChangeList
            }
        case 'GET_SEEN_MAIL_INFO_CHANGE':
            return {
                ...state,
                userMailInfoSeenChangeList: action.userMailInfoSeenChangeList
            }
        default:
            return state;
    }
}

export default userMailInfoChangeReducer;