const INITIALSTATE = {
    personVac: [],
    vacDetail: []
}

const VacationReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'GET_VAC_BY_PERSON':
            return {
                ...state,
                personVac: action.personVac
            }
        case 'GET_VAC_DETAIL':
            return {
                ...state,
                vacDetail: action.vacDetail
            }

        default:
            return state
    }
}

export default VacationReducer