const initialState = {
    userInfoChange: {},
    userInfoSeenChangeList: [],
    userInfoNotSeenChangeList: []
}


const userInfoChangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_INFO_CHANGE':
            return {
                ...state,
                userInfoChange: action.userInfoChange
            }
        case 'GET_SEEN_INFO_CHANGE':
            return {
                ...state,
                userInfoSeenChangeList: action.userInfoSeenChangeList
            }
        case 'GET_NOT_SEEN_INFO_CHANGE':
            return {
                ...state,
                userInfoNotSeenChangeList: action.userInfoNotSeenChangeList
            }
        default:
            return state;
    }
}

export default userInfoChangeReducer;