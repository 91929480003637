const INITIALSTATE = {
    contentReport: {},
    getAnnouncementRes: [],
    categoryUnitList: []
}

const ContentReportReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'CONTENT_REPORT':
            return {
                ...state,
                contentReport: action.contentReport,
            };
        case 'GET_USER_ANNOUNCEMENT':
            return {
                ...state,
                getAnnouncementRes: action.getAnnouncementRes,
            };
        case 'GET_CATEGORY_UNIT_BY_USERID':
            return {
                ...state,
                categoryUnitList: action.categoryUnitList,
            };
        default:
            return state;
    }
}

export default ContentReportReducer