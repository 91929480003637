// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth/reducer'
import navbar from './navbar/reducer'
import layout from './layout/reducer'
// import person from './person/reducer'
import loading from './loading/reducer'
import unit from './userUnit/reducer'
import vacation from './vacation/reducer'

//new

import common from "./common/reducer"
import user from "./user/reducer"
import userInfoChange from "./userInfoChange/reducer"
import userUnit from "./userUnit/reducerNew"
import debt from "./debt/reducer"
import debtPayment from "./debtPayment/reducer"
import userImage from "./userImage/reducer"
// import vacationDeserve from "./vacationDeserve/reducer"
// import userImageReducer from "./userImage/reducer"
import vacationDeserve from "./vacationDeserve/reducer"
import vacationUsage from "./vacationUsage/reducer"
import claimCategory from './claimCategory/reducer'
import report from './report/reducer'
import payroll from './payroll/reducer'
import userAddressInfoChange from './userAddressInfoChange/reducer'
import userMailInfoChange from './userMailInfoChange/reducer'
import suggestion from './suggestion/reducer'
//LMS

import UnitTitleReducer from './lmsredux/unitTitle/reducer'
import ContentReportReducer from './lmsredux/contentReport/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  // person,
  loading,
  unit,
  vacation,

  ///new 
  common,
  user,
  userInfoChange,
  userAddressInfoChange,
  userMailInfoChange,
  userUnit,
  debt,
  debtPayment,
  userImage,
  // userInfoChangeReducer,
  vacationDeserve,
  vacationUsage,
  claimCategory,
  report,
  payroll,
  suggestion,

  //lms
  UnitTitleReducer,
  ContentReportReducer
})

export default rootReducer
