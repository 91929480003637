const initialState = {
    unitList: [],
    total: 0,
    userUnit: ""
}

const UserUnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_UNİT':
            return {
                ...state,
                unitList: action.unitList
            }
        case 'GET_USER_UNIT':
            return {
                ...state,
                userUnit: action.userUnit
            }


        default:
            return state
    }
}

export default UserUnitReducer