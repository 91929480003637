const initialState = {
    userImg: []
}


const userImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_IMAGE':
            return {
                ...state,
                userImg: action.userImg
            }
        default:
            return state;
    }
}

export default userImageReducer;