const initialState = {
    unitList: [],
}

const UnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_UNİT':
            return {
                ...state,
                unitList: action.unitList
            }


        default:
            return state
    }
}

export default UnitReducer