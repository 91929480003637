const initalState = {
    getVacationDeserveByDeserveId: {},
    getVacationDeserveByUserId: [],
    getTotalVacationDay: []
}


const VacationdeserveReducer = (state = initalState, action) => {
    switch (action.type) {
        case 'GET_VACATION_DESERVE_BY_DESERVE_ID':
            return {
                ...state,
                getVacationDeserveByDeserveId: action.getVacationDeserveByDeserveId
            };
        case 'GET_VACATION_DESERVE_BY_USER_ID':
            return {
                ...state,
                getVacationDeserveByUserId: action.getVacationDeserveByUserId
            }
        case 'GET_TOTAL_VACATION_DAY':
            return {
                ...state,
                getTotalVacationDay: action.getTotalVacationDay
            }
        default:
            return state
    }
}

export default VacationdeserveReducer