const initialState = {
    payroll : [],
    annualPayroll:[],
    sumData:0,
    dataState:false
}

const PayrollReducer = (state=initialState, action) => {
    switch (action.type) {
        case "GET_PAYROLL":
            return {
                ...state,
                payroll : action.payroll
            }
        case "GET_ANNUAL_PAYROLL":
            return {
                ...state,
                annualPayroll : action.annualPayroll,
                sumData:action.sumData,
                dataState:action.dataState
            }
        default:
            return state;
    }
}

export default PayrollReducer;