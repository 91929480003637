const initialState = {
    userList: [],
    user: {}
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_USER':
            return {
                ...state,
                userList: action.userList
            }
        case 'GET_USER':
            return {
                ...state,
                user: action.user
            }
        default:
            return state
    }
}

export default userReducer;