const initialState = {
    montlyVacationUsageList: [],
    montlyDebtPaymentList: [],
    allUserVac:[]
}


const ReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "MONTLY_VACATION_USAGE_LIST_REPORT":
            return {
                ...state,
                montlyVacationUsageList: action.montlyVacationUsageList
            }
        case "MONTLY_DEBT_PAYMENT_LIST_REPORT":
            return {
                ...state,
                montlyDebtPaymentList: action.montlyDebtPaymentList
            }
            case "GET_ALL_USER_VACATIONS":
                return{
                    ...state,
                    alUserVac: action.allUserVac
                }
        default:
            return state;
    }
}

export default ReportReducer;