const initialState = {
    debtPayment: [],
}

const DebtPaymentReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'GET_ALL_DEBT_PAYMENTS_BY_DEBT_ID':

            return {
                ...state,
                debtPayment: action.debtPayment
            }

        default:
            return state
    }


}


export default DebtPaymentReducer