// **  Initial State
const initialState = {
  userData: {},
  userClaimListById: [],
  claimList: [],
  userRoleList: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    case 'GET_CLAIM_LIST_BY_USER_ID':
      return {
        ...state,
        userClaimListById: action.userClaimListById
      }
    case 'GET_CLAIM_LIST':
      return {
        ...state,
        claimList: action.claimList
      }
    case 'USER_ROLES':
      return {
        ...state,
        userRoleList: action.userRoleList
      }
    default:
      return state
  }
}

export default authReducer
