const INITIALSTATE = {
    getDebt: {},
    getDebtByUserId: [],
    getTotalDebt: [],
    getOnGoingDebts: [],
    expiredDebts: []
}

const DebtReducer = (state = INITIALSTATE, action) => {
    switch (action.type) {
        case 'GET_DEBT_BY_DEBT_ID':
            return {
                ...state,
                getDebt: action.getDebt
            }
        case 'GET_DEBT_LIST_BY_USER_ID':
            return {
                ...state,
                getDebtByUserId: action.getDebtByUserId,
                getOnGoingDebts: action.getOnGoingDebts,
                expiredDebts: action.expiredDebts
            }
        case 'GET_TOTAL_DEBT_BY_USER_ID':
            return {
                ...state,
                getTotalDebt: action.getTotalDebt
            }
        case 'GET_ALL_DEBTS':
            return {
                ...state,
                getAllDebtsList: action.getAllDebtsList
            }

        default:
            return state
    }
}

export default DebtReducer